import request from '@/utils/request.js'

export async function login(data) {
  return request({
    url: '/api/maintenance/userLogin',
    method: 'GET',
    params:data,
    data,
  })
}

export function getUserInfo(accessToken) {
  return request({
    url: '/api/maintenance/userInfo',
    method: 'GET',
    params:{session: accessToken},
    data: {
      session: accessToken
    },
  })
}

export function getUserSecondSession(uid) {
  return request({
    url: '/api/maintenance/getUserSecondSession',
    method: 'GET',
    params:{uid: uid},
    data: {
      uid: uid
    },
  })
}

export function logout() {
  return request({
    url: '/api/maintenance/logout',
    method: 'GET',
  })
}

export function register() {
  return request({
    url: '/api/maintenance/register',
    method: 'post',
  })
}
