
// const requireZxLayouts = require.context('zx-layouts', true, /\.vue$/)
// requireZxLayouts.keys().forEach((fileName) => {
//   const componentConfig = requireZxLayouts(fileName)
//   const componentName = componentConfig.default.name
//   this.$app.component(componentName, componentConfig.default || componentConfig)
// })

const requireThemes=require.context('@/styles/themes', true, /\.*$/)
requireThemes.keys().forEach((fileName) => {
  require(`@/styles/themes/${fileName.slice(2)}`)
})
