import { createApp } from 'vue'
import App from './App.vue'

import store from './store/index.js'
import router from '@/router'
import '@/layouts/export'
import axios from 'axios'
import setting from '@/config/setting.config.js'
import theme from '@/config/theme.config.js'
import network from '@/config/net.config.js'

import ElementPlus, {ElLoading, ElMessage} from 'element-plus';
import 'element-plus/theme-chalk/index.css'; // 导入样式表

// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
import { charts } from '@jiaminghi/data-view'; // 引入 DataView 图表组件

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import 'lib-flexible/flexible'
import '@/utils/rem'

import install from '@/utils/vab'

import echarts from 'echarts'

import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

const app=createApp(App);
app.config.globalProperties.productionTip = false
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$BASE_URL_PC = setting.baseURL;
app.config.globalProperties.$appTitle=setting.title;
app.config.globalProperties.$store=store
app.config.globalProperties.$echarts=echarts

app.use(router)
app.use(store)
app.use(ElementPlus)
app.use(dataV)
app.use(charts)
app.use(install,{})
app.use(Viewer, {
    defaultOptions: {
        inline: false, //默认值：false。启用内联模式。
        button: true, //在查看器的右上角显示按钮。
        navbar: true, //指定导航栏的可见性。
        title: true, //指定标题的可见性和内容。
        toolbar: true, //指定工具栏及其按钮的可见性和布局。
        tooltip: true, //放大或缩小时显示带有图像比率（百分比）的工具提示。
        movable: true, //启用以移动图像。
        zoomable: true, //启用以缩放图像
        rotatable: true, //启用以旋转图像
        scalable: true, //启用以缩放图像。
        transition: true, //为某些特殊元素启用CSS3转换。
        fullscreen: false, //启用以在播放时请求全屏。
        keyboard: true, //启用键盘支持。
        // url: 'src',  //默认值：'src'。定义获取原始图像URL以供查看的位置。
    },
});




for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.mount('#app')
