import setting from '@/config/setting.config.js'
import theme from '@/config/theme.config.js'
import network from '@/config/net.config.js'
import * as lodash from 'lodash'
import {ElLoading, ElMessage, ElMessageBox, ElNotification} from 'element-plus'
// import store from '@/store/index.js'
// import {getAccessToken} from '@/utils/accessToken.js'

// const accessToken = store.getters['user/accessToken']


const vab2 = {
    // $baseAccessToken :store.getters['user/accessToken']|| getAccessToken(),
    /* 全局accessToken */

    /* 全局标题 */
    $baseTitle: (() => {
        return setting.title
    })(),
    /* 全局加载层 */
    $baseLoading: (index, text) => {
        let loading
        if (!index) {
            loading = ElLoading.service({
                lock: true,
                text: text || setting.loadingText,
                background: 'hsla(0,0%,0%,0.5)',
            })
        } else {
            loading = ElLoading.service({
                lock: true,
                text: text || setting.loadingText,
                spinner: 'vab-loading-type' + index,
                background: 'hsla(0,0%,0%,0.5)',
            })
        }
        return loading
    },
    /* 全局多彩加载层 */
    $baseColorfullLoading: (index, text) => {
        let loading
        if (!index) {
            loading = ElLoading.service({
                lock: true,
                text: text || setting.loadingText,
                spinner: 'dots-loader',
                background: 'hsla(0,0%,0%,0.5)',
            })
        } else {
            switch (index) {
                case 1:
                    index = 'dots'
                    break
                case 2:
                    index = 'gauge'
                    break
                case 3:
                    index = 'inner-circles'
                    break
                case 4:
                    index = 'plus'
                    break
            }
            loading = ElLoading.service({
                lock: true,
                text: text || setting.loadingText,
                spinner: index + '-loader',
                background: 'hsla(0,0%,0%,0.5)',
            })
        }
        return loading
    },
    /* 全局Message */
    $baseMessage: (message, type) => {
        ElMessage({
            offset: 60,
            showClose: true,
            message: message,
            type: type,
            dangerouslyUseHTMLString: true,
            duration: network.messageDuration,
        })
    },

    /* 全局Alert */
    $baseAlert: (content, title, callback) => {
        ElMessageBox.alert(content, title || '温馨提示', {
            confirmButtonText: '确定',
            dangerouslyUseHTMLString: true,
            callback: () => {
                if (callback) {
                    callback()
                }
            },
        })
    },

    /* 全局Confirm */
    $baseConfirm: (content, title, callback1, callback2) => {
        ElMessageBox.confirm(content, title || '温馨提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            closeOnClickModal: false,
            type: 'warning',
        })
            .then(() => {
                if (callback1) {
                    callback1()
                }
            })
            .catch(() => {
                if (callback2) {
                    callback2()
                }
            })
    },

    /* 全局Notification */
    $baseNotify: (message, title, type, position) => {
        ElNotification({
            title: title,
            message: message,
            position: position || 'top-right',
            type: type || 'success',
            duration: network.messageDuration,
        })
    }
}
/* 全局lodash */
// app.config.globalProperties.$baseLodash = lodash
/* 全局事件总线 */
// app.config.globalProperties.$baseEventBus = app;

export default vab2


