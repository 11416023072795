import {
    createWebHistory,
    createRouter
} from 'vue-router'
import setting from '@/config/setting.config.js'
import theme from '@/config/theme.config.js'
import network from '@/config/net.config.js'
import getPageTitle from "@/utils/pageTitle.js";

import store from '@/store'
import VabProgress from 'nprogress'
import 'nprogress/nprogress.css'
import HelloWorld from '@/components/HelloWorld'
import EmptyLayout from '@/layouts/EmptyLayout'

export const constantRoutes = [
    {
        path: '/',
        redirect: '/login'
    },
    // {
    //     path: '/',
    //     redirect: 'home',
    //     // component: () => import("../pages/PC/home/HomePage"),
    // },
    {
        path: '/login',
        name: 'login',
        component: () => import("../pages/PC/home/Login")
    },
    {
        path: '/home',
        name: 'home',
        component: () => import("../pages/PC/home/HomePage"),
    },
    {
        path: '/welcome',
        name: 'welcome',
        component: HelloWorld
    },
    {
        path: '/401',
        name: '401',
        component: () => import('@/layouts/401'),
        hidden: false,
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/layouts/404'),
        hidden: false,
    },
    // ... 添加更多路由配置
];

export const asyncRoutes = [
    {
        path: '/error',
        component: EmptyLayout,
        redirect: 'noRedirect',
        name: 'Error',
        meta: {title: '错误页', icon: 'bug'},
        children: [
            {
                path: '401',
                name: 'Error401',
                component: () => import('@/layouts/401'),
                meta: {title: '401'},
            },
            {
                path: '404',
                name: 'Error404',
                component: () => import('@/layouts/404'),
                meta: {title: '404'},
            },
        ],
    },
    {
        path: '*',
        redirect: '/404',
        hidden: false,
    }
];

const router = createRouter({
    base: setting.publicPath,
    mode: setting.routerMode,
    scrollBehavior: () => ({
        y: 0,
    }),
    history: createWebHistory(),
    routes: constantRoutes,
});


export function resetRouter() {
    router.matcher = new createRouter({
        base: setting.publicPath,
        mode: setting.routerMode,
        scrollBehavior: () => ({
            y: 0,
        }),
        routes: constantRoutes,
    }).matcher
}

VabProgress.configure({
    easing: 'ease',
    speed: 500,
    trickleSpeed: 200,
    showSpinner: false,
})
router.beforeResolve(async (to, from, next) => {
    if (setting.progressBar) VabProgress.start()
    let hasToken = store.getters['user/accessToken']

    if (!setting.loginInterception) hasToken = true

    if (hasToken) {
        if (to.path === '/login') {
            next({path: '/'})
            if (setting.progressBar) VabProgress.done()
        } else {
            const hasPermissions =
                store.getters['user/permissions'] &&
                store.getters['user/permissions'].length > 0
            if (hasPermissions) {
                next()
            } else {
                try {
                    let permissions
                    if (!setting.loginInterception) {
                        //settings.js loginInterception为false时，创建虚拟权限
                        await store.dispatch('user/setPermissions', ['admin'])
                        permissions = ['admin']
                    } else {
                        permissions = await store.dispatch('user/getUserInfo')
                    }

                    let accessRoutes = []
                    if (setting.authentication === 'intelligence') {
                        accessRoutes = await store.dispatch('routes/setRoutes', permissions)
                    } else if (setting.authentication === 'all') {
                        accessRoutes = await store.dispatch('routes/setAllRoutes')
                    }
                    accessRoutes.forEach(item => {
                        router.addRoute(item)
                    })

                    next({...to, replace: true})
                } catch {
                    await store.dispatch('user/resetAccessToken')
                    if (setting.progressBar) VabProgress.done()
                }
            }
        }
    } else {
        if (setting.routesWhiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            if (setting.recordRoute) {
                next(`/login?redirect=${to.path}`)
            } else {
                next('/login')
            }

            if (setting.progressBar) VabProgress.done()
        }
    }
    document.title = getPageTitle(to.meta.title)
})
router.afterEach(() => {
    if (setting.progressBar) VabProgress.done()
})

// 路由错误监听
router.onError((error) => {
    console.log("bbbbbbbbbbbbbb",error);
    // 这里可以编写错误处理逻辑，比如重定向到错误页面
    const { to, from } = error;
    if (to.matched.length === 0) {
        router.push('/404'); // 假设有一个名为'/404'的路由
    }else{
        router.push('/401'); // 假设有一个名为'/401'的路由
    }
});

export default router;