<template>
  <view class="app-content">
<!--    <dv-full-screen-container class="app-screen">-->
      <router-view class="viewrouter"></router-view>
<!--    </dv-full-screen-container>-->
  </view>
</template>

<script>

import {View} from "@element-plus/icons-vue";
export default {
  name: 'App',
  components: {
    View
  },
  data(){
    return {
      windowHeight:0,
    }
  },
  mounted() {
    this.getWindowHeight();
    // window.addEventListener('resize',this.windowHeight);
  },
  methods:{
    getAddressBarHeight() {
      // const addressBarHeight = window.innerHeight - document.documentElement.clientHeight;
      // console.log('底部地址栏的高度是：', addressBarHeight);
      // 如果需要在Vue实例中使用这个高度，可以将其设置为数据属性或Vuex状态

    },
    getWindowHeight(){
      // this.windowHeight=document.documentElement.clientHeight;
      // console.log('底部地址栏的高度是1：',  window.innerHeight);
      // console.log('底部地址栏的高度是2：', this.windowHeight);
    }
  },
  beforeUnmount() {
    // window.removeEventListener('resize',this.windowHeight);
  }
}
</script>

<style lang="less" scoped>
.app-content {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;

  .app-screen{
    height: 100vh;
  }

  .viewrouter{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

</style>
