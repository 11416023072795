import axios from 'axios'
import setting from '@/config/setting.config.js'
import theme from '@/config/theme.config.js'
import network from '@/config/net.config.js'
import store from '@/store/index.js'
import qs from 'qs'
import router from '@/router/index.js'
import {isArray} from '@/utils/validate.js'
import vab2 from "@/utils/vab2"

let loadingInstance

/**
 * @description 处理code异常
 * @param {*} code
 * @param {*} msg
 */

const handleCode = (code, msg) => {

    switch (code) {
        case network.invalidCode:
            vab2.$baseMessage(`登陆过期，请重新登陆！`, 'info')
            vab2.$baseNotify(`请重新登陆！`, `登陆过期`)
            store.dispatch('user/resetAccessToken').catch(() => {
            })
            if (setting.loginInterception) {
                setTimeout(() => {
                    location.reload()
                }, 3000)
            }
            break
        case network.noPermissionCode:
            router.push({path: '/401'}).catch(() => {
            })
            break
        default:
            vab2.$baseMessage(msg || `接口${code}异常`, 'error')
            break
    }
}

const instance = axios.create({
    baseURL:setting.baseURL,
    timeout: network.requestTimeout,
    headers: {
        'Content-Type': network.contentType,
    },
})

instance.interceptors.request.use(
    (config) => {
        if(config.loading){
            loadingInstance = vab2.$baseLoading()
        }
        if (store.getters['user/accessToken']) {
            // config.headers[tokenName] = store.getters['user/accessToken']
            // config.headers['Cookie']="session=" + store.getters['user/accessToken']
            // config.data['session']=store.getters['user/accessToken']
        }
        //这里会过滤所有为空、0、false的key，如果不需要请自行注释
        // if (config.data)
            // config.data = this.$app.$baseLodash.pickBy(
            //     config.data,
            //     this.$app.$baseLodash.identity
            // )
        if (
            config.data &&
            config.headers['Content-Type'] ===
            'application/x-www-form-urlencoded;charset=UTF-8'
        )
            config.data = qs.stringify(config.data)
        if (setting.debounce.some((item) => config.url.includes(item)))
            loadingInstance = vab2.$baseLoading()
        console.log("RequestConfig===>", config)
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (response) => {
        console.log("服务器返回结果==gangmiss==>")

        console.log(response)
        const {data, config} = response
        // if (loadingInstance && config.loading) loadingInstance.close()
        if (loadingInstance) loadingInstance.close()
        const {code, msg} = data
        // 操作正常Code数组
        const codeVerificationArray = isArray(network.successCode)
            ? [...network.successCode]
            : [...[network.successCode]]
        // 是否操作正常

        if (!network.isSuccessCodeIntercept||codeVerificationArray.includes(code)) {
            return data
        } else {
            handleCode(code, msg)
            return Promise.reject(
                '请求异常拦截:' +
                JSON.stringify({url: config.url, code, msg}) || 'Error'
            )
        }
    },
    (error) => {
        console.log("服务器返回错误结果==gangmiss==>")
        console.log(error)
        if (loadingInstance) loadingInstance.close()
        const {response, message} = error
        if (error.response && error.response.data) {
            const {code, data} = response
            handleCode(code, data.msg || message)
            return Promise.reject(error)
        } else {
            let {message} = error
            if (message === 'Network Error') {
                message = '接口连接异常'
            }
            if (message.includes('timeout')) {
                message = '接口请求超时'
            }
            if (message.includes('Request failed with status code')) {
                // const code = message.substr(message.length - 3)
                message = '接口' + code + '异常'
            }
            vab2.$baseMessage(message || `接口未知异常`, 'error')
            return Promise.reject(error)
        }
    }
)

export default instance
