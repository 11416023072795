import setting from '@/config/setting.config.js'
import theme from '@/config/theme.config.js'
import network from '@/config/net.config.js'

/**
 * @description 获取accessToken
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getAccessToken() {
  if (setting.storage) {
    if ('localStorage' === setting.storage) {
      return localStorage.getItem(setting.tokenTableName)
    } else if ('sessionStorage' === setting.storage) {
      return sessionStorage.getItem(setting.tokenTableName)
    } else {
      return localStorage.getItem(setting.tokenTableName)
    }
  } else {
    return localStorage.getItem(setting.tokenTableName)
  }
}

/**
 * @description 存储accessToken
 * @param accessToken
 * @returns {void|*}
 */
export function setAccessToken(accessToken) {
  if (setting.storage) {
    if ('localStorage' === setting.storage) {
      return localStorage.setItem(setting.tokenTableName, setting.accessToken)
    } else if ('sessionStorage' === setting.storage) {
      return sessionStorage.setItem(setting.tokenTableName, setting.accessToken)
    } else {
      return localStorage.setItem(setting.tokenTableName, setting.accessToken)
    }
  } else {
    return localStorage.setItem(setting.tokenTableName, setting.accessToken)
  }
}

/**
 * @description 移除accessToken
 * @returns {void|Promise<void>}
 */
export function removeAccessToken() {
  if (setting.storage) {
    if ('localStorage' === setting.storage) {
      return localStorage.removeItem(setting.tokenTableName)
    } else if ('sessionStorage' === setting.storage) {
      return sessionStorage.clear()
    } else {
      return localStorage.removeItem(setting.tokenTableName)
    }
  } else {
    return localStorage.removeItem(setting.tokenTableName)
  }
}

//模拟用户进入系统
export function getSecondToken() {
  if (setting.storage) {
    if ('localStorage' === setting.storage) {
      return localStorage.getItem(setting.tokenTableSecond)
    } else if ('sessionStorage' === setting.storage) {
      return sessionStorage.getItem(setting.tokenTableSecond)
    } else {
      return localStorage.getItem(setting.tokenTableSecond)
    }
  } else {
    return localStorage.getItem(setting.tokenTableSecond)
  }
}

/**
 * @description 存储accessToken
 * @param accessToken
 * @returns {void|*}
 */
export function setSecondToken(accessToken) {
  if (setting.storage) {
    if ('localStorage' === setting.storage) {
      return localStorage.setItem(setting.tokenTableSecond, setting.accessToken)
    } else if ('sessionStorage' === setting.storage) {
      return sessionStorage.setItem(setting.tokenTableSecond, setting.accessToken)
    } else {
      return localStorage.setItem(setting.tokenTableSecond, setting.accessToken)
    }
  } else {
    return localStorage.setItem(setting.tokenTableSecond, setting.accessToken)
  }
}

/**
 * @description 移除accessToken
 * @returns {void|Promise<void>}
 */
export function removeSecondToken() {
  if (setting.storage) {
    if ('localStorage' === setting.storage) {
      return localStorage.removeItem(setting.tokenTableSecond)
    } else if ('sessionStorage' === setting.storage) {
      return sessionStorage.clear()
    } else {
      return localStorage.removeItem(setting.tokenTableSecond)
    }
  } else {
    return localStorage.removeItem(setting.tokenTableSecond)
  }
}

