import setting from '@/config/setting.config.js'
import theme from '@/config/theme.config.js'
import network from '@/config/net.config.js'

/**
 * @description 设置标题
 * @param pageTitle
 * @returns {string}
 */
export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle}-${setting.title}`
  }
  return `${setting.title}`
}
