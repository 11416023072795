/**
 * @description 登录、获取用户信息、退出登录、清除accessToken逻辑
 */

import {getUserInfo, getUserSecondSession, login, logout} from '@/api/user'
import {
  getAccessToken,
  removeAccessToken,
  setAccessToken,
  getSecondToken,
  removeSecondToken,
  setSecondToken,
} from '@/utils/accessToken.js'
import { resetRouter } from '@/router'
import setting from '@/config/setting.config.js'
import theme from '@/config/theme.config.js'
import network from '@/config/net.config.js'

const state = () => ({
  accessToken: getAccessToken(),
  username: '',
  avatar: '',
  permissions: [],
  userSecondSession:getSecondToken(),
})
const getters = {
  accessToken: (state) => state.accessToken,
  username: (state) => state.username,
  avatar: (state) => state.avatar,
  permissions: (state) => state.permissions,
  userSecondSession:(state)=>state.userSecondSession,
}
const mutations = {
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken
    setAccessToken(accessToken)
  },
  setUsername(state, username) {
    state.username = username
  },
  setAvatar(state, avatar) {
    state.avatar = avatar
  },
  setPermissions(state, permissions) {
    state.permissions = permissions
  },
  setUserSecondSession(state,secondSession){
    state.userSecondSession=secondSession
    setSecondToken(secondSession)
  }
}
const actions = {
  setPermissions({ commit }, permissions) {
    commit('setPermissions', permissions)
  },
  async login({ commit }, userInfo) {
    const { ret } = await login(userInfo)
    const accessToken = ret[setting.tokenName]
    if (accessToken) {
      commit('setAccessToken', accessToken)
      const hour = new Date().getHours()
      const thisTime =
        hour < 8
          ? '早上好'
          : hour <= 11
          ? '上午好'
          : hour <= 13
          ? '中午好'
          : hour < 18
          ? '下午好'
          : '晚上好'
      this.$app.$baseNotify(`欢迎登录${setting.title}`, `${thisTime}！`)
    } else {
      this.$app.$baseMessage(
        `登录接口异常，未正确返回${setting.tokenName}...`,
        'error'
      )
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getUserSecondSession({commit,state},uid){
    const {status, ret } = await getUserSecondSession(uid)
    if (!ret) {
      this.$app.prototype.$baseMessage('进入用户系统失败！', 'error')
      return false
    }
    if(status==200&&ret){
      commit('setUserSecondSession',ret.session)
      return true
    }else{
      return false
    }
  },
  async getUserInfo({ commit, state }) {
    const { ret } = await getUserInfo(state.accessToken)
    if (!ret) {
      this.$app.prototype.$baseMessage('验证失败，请重新登录...', 'error')
      return false
    }
    let { permissions, loginName, avatar } = ret
    if (permissions && loginName && Array.isArray(permissions)) {
      commit('setPermissions', permissions)
      commit('setUsername', loginName)
      commit('setAvatar', avatar)
      return permissions
    } else {
      this.$app.prototype.$baseMessage('用户信息接口异常', 'error')
      return false
    }
  },
  async logout({ dispatch }) {
    await logout(state.accessToken)
    await dispatch('resetAccessToken')
    await resetRouter()
  },
  resetAccessToken({ commit }) {
    commit('setPermissions', [])
    commit('setAccessToken', '')
    commit('setUserSecondSession', '')
    removeAccessToken()
    removeSecondToken()
  },
}
export default { state, getters, mutations, actions }
